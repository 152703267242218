<template>
  <compDisclaimer v-if="!this.acceptDisclaimer" :showAcceptBtn="true" />
  <div v-else class="bgImage fontGigya formGigya flex">
    <div class="panelLogin">
      <div class="paddingRegistration">
        <div id="panelLogo">
          <div id="panelLogoService" class="panelImg">
            <div class="resizedLogo"></div>
          </div>
          <div id="panelLogoBrand" class="panelImg">
            <img src="@/assets/logo_age_go.png" class="mt5 mb5 resizedBrand" />
          </div>
        </div>

        <div id="toogglebtn" class="flex padding5px togglePanel mb20">
          <div class="toggleActive pointer">{{ gigyaAccedi }}</div>
          <div class="toggleDefault" @click="setCompToRender('compRegister')">
            <span>{{ gigyaRegistrati }}</span>
          </div>
        </div>

        <div class="panelLoginRow">
          <label for="email_in" class="bold fa-1x pull-left mt10">{{
            labelUsername
          }}</label>
          <input
            id="email_in"
            name="email_in"
            v-model="username"
            type="text"
            placeholder="Inserisci l'indirizzo E-mail"
            class="inputStyle textIndent h36-nomargin"
            autocomplete="off"
          />
        </div>

        <div>
          <div class="flex">
            <label for="user_password_in" class="bold fa-1x mt10 textStart">{{
              labelPassword
            }}</label
            ><a
              id="pwd_reset"
              href="/horta_menu/faces/gigya_pwd_ask_reset.xhtml"
              >{{ gigyaForgetPwd }}</a
            >
          </div>
          <div class="flex relative">
            <input
              id="user_password_in"
              name="user_password_in"
              v-model="password"
              placeholder="Inserisci la password"
              :type="inputType"
              autocomplete="off"
              class="inputStyle textIndent h36-nomargin"
            />

            <span @click="showPassword" class="showPassword">
              <font-awesome-icon
                :icon="['fas', eyeIcon]"
                :style="{ color: 'black' }"
              />
            </span>
          </div>
        </div>

        <div
          id="submit_label"
          class="panelLoginRow buttonGigya mt20 pointer"
          @click="submit"
        >
          {{ labelEnter }}
        </div>

        <div class="mt20">
          <img id="curr_flag" :src="urlFlag" />
          <div class="invisibleGigyaLang">
            <select
              id="lang_id_in"
              name="flag"
              v-model="locale"
              @change="changeLocale()"
              class="flagSelector"
            >
              <option value="IT">{{ labelIT }}</option>
              <option value="EN">{{ labelEN }}</option>
              <option value="ES">{{ labelES }}</option>
              <option value="PT">{{ labelPT }}</option>
              <option value="TR">{{ labelTR }}</option>
              <option value="FR">{{ labelFR }}</option>
            </select>
          </div>
        </div>

        <!-- DIV PER LOGO HORTA -->
        <div id="logo_horta" class="lastDiv"></div>

        <div id="copyright"><i class="fa fa-copyright"></i> Horta s.r.l.</div>
        <div id="legalese">
          <a href="#"
            ><span>{{ gigyaTermini }}</span></a
          >
          <span> | </span>
          <a
            href="https://www.agro.basf.it/it/Data-privacy.html"
            target="_blank"
            ><span>{{ gigyaInformativa }}</span></a
          >
        </div>
      </div>
    </div>
    <div id="waitingPanel" class="bg" style="display:none;">
      <div class="waiting">
        <div class="headerWaiting">
          {{ labeWaiting }}
        </div>
        <div class="bodyWaiting">
          {{ labelElab }}
        </div>
        <div class="loaderIcon">
          <img src="/../images/agrishare/horta/ajax-loader.gif" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { store } from "../Store/store.js";
import queries from "../mixins/queries.js";
import locales from "../mixins/locales.js";
import cache from "../mixins/cache.js";

import compDisclaimer from "./Disclaimer.vue";

export default {
  data() {
    return {
      username: "",
      password: "",
      locale: "IT",
      inputType: "password",
      eyeIcon: "eye",
      urlFlag:
        "https://cloudtest.horta-srl.it/horta_menu/resources/images/flags/it_w.png"
    };
  },
  components: {
    compDisclaimer
  },
  mixins: [queries, locales, cache],
  computed: {
    ...mapGetters({
      token: "token",
      role: "role",
      reteID: "reteID",
      globalLocale: "globalLocale",
      acceptDisclaimer: "acceptDisclaimer",
      maxUp: "maxUp"
    })
  },
  methods: {
    ...mapMutations([
      "setToken",
      "setPassword",
      "setUsername",
      "setCompToRender",
      "setShowNoAziende",
      "setShowNoUp",
      "setItemInfo",
      "setRole",
      "setReteID",
      "setGlobalLocale",
      "setAcceptDisclaimer",
      "setMaxUp"
    ]),
    submit() {
      let countAziende = 0;
      let countUps = 0;
      if (this.username && this.password) {
        document.getElementById("waitingPanel").style.display = "block";
        this.retrieveToken(this.username.toLowerCase(), this.password)
          .then(response => {
            return response.data?.[0].token;
          })
          .then(token => {
            if (token.Issuer == "HORTA") {
              //gigya
              this.setToken(token.Bearer);
              this.setCookies("gigya", null);
              return this.chiaviServizi(token.Bearer);
            } else {
              this.hermes(token.Bearer);
              return this.retrieveAuthKey(this.token);
            }
          })
          .then(result => {
            const authKeys = result.data?.data?.authKeys;
            const isLegacy = authKeys != undefined;
            const isV2 = result.data?.esito != undefined;
            this.saveAuth(this.username.toLowerCase(), this.password);

            if (isLegacy) {
              authKeys.forEach(k => {
                const id = k.ID;
                if (id.indexOf("MAX_UP_AVAILABLE") > -1) {
                  const split = id.split("#");
                  if (split[0] == "MAX_UP_AVAILABLE") {
                    this.setMaxUp(split[1]);
                  }
                }
              });
              return this.retrieveMasterAziende(this.token);
            } else if (isV2) {
              //
              const esito = result.data?.esito;
              if (esito == 1) {
                throw new Error("User role is not set!");
              } else {
                return this.retrieveMasterAziende(this.token);
              }
            } else {
              throw new Error("no user_id&password neither horta_token found!");
            }
          })
          .then(aziende => {
            const listAziende = aziende.data?.data?.masterAziende;
            countAziende = listAziende.length;

            return this.retrieveMasterUp(this.token, this.globalLocale, true);
          })
          .then(ups => {
            const listUps = ups.data?.data?.masterUP;
            countUps = listUps.length;

            if (countUps == 0) {
              return this.retrieveMasterUp(
                this.token,
                this.globalLocale,
                false
              );
            } else return ups;
          })
          .then(ups => {
            const listUps = ups.data?.data?.masterUP;
            countUps = listUps.length;

            this.moveTo(countAziende, countUps);
          })
          .catch(error => {
            console.log(error);
            const err401 = "Request failed with status code 401";
            const errRole = "User role is not set!";
            if (error.toString().indexOf(err401) > -1) {
              this.setCompToRender("compErrorLogin");
              return;
            }
            if (error.toString().indexOf(errRole) > -1) {
              this.setCompToRender("compErrorNoService");
              return;
            }
            this.setCompToRender("compError");
          });
      } else {
        let message = {
          title: this.labelWarn,
          body: this.warnLogin
        };
        let options = {
          okText: this.labelCloseBtn
        };
        this.$dialog.alert(message, options);
      }
    },
    moveTo(countAziende, countUps) {
      if (countAziende == 0) {
        this.setItemInfo("");
        this.setShowNoAziende(true);
        this.setCompToRender("compHome");

        return;
      }

      if (countUps == 0) {
        this.setShowNoUp(true);
        this.setCompToRender("compHome");

        return;
      }

      this.setUsername(this.username);
      this.setPassword(this.password);
      this.setCompToRender("compUp");
    },
    changeLocale() {
      this.setGlobalLocale(this.locale);
      this.urlFlag =
        "https://cloudtest.horta-srl.it/horta_menu/resources/images/flags/" +
        this.locale.toLowerCase() +
        "_w.png";
      document.title = this.headerTitle;
    },
    hermes(hermesBearer) {
      const splitted = hermesBearer.split(".");

      let bearer = "";
      const ls = splitted.length;
      for (let i = 0; i < ls - 1; ++i) {
        bearer = bearer + "." + splitted[i];
      }
      bearer = bearer.substring(1);
      const last = splitted[ls - 1];
      const reverse = last
        .split("")
        .reverse()
        .join("");
      const uppercase = reverse.toUpperCase();

      this.setToken(bearer, uppercase);
      this.setCookies("legacy", uppercase);
    },
    setInputType(type) {
      this.inputType = type;
    },
    cleanServiceWorker() {
      this.loading();
      if (typeof navigator.serviceWorker !== "undefined") {
        navigator.serviceWorker
          .getRegistrations()
          .then(registrations => {
            registrations.forEach(registration => {
              registration.unregister();
            });
          })
          .then(() => {
            this.$router.go();
          });
      }
      setTimeout(this.loaded, 2500);
    },
    loading() {
      document.getElementById("waitingPanel").style.display = "block";
    },
    loaded() {
      document.getElementById("waitingPanel").style.display = "none";
    },
    setCookies(type, pwd) {
      let domain = store.state.domain;
      const hostname = window.location.hostname;
      if (hostname.indexOf("horta-stg") > -1) {
        domain = "horta-stg.basf.com";
      }
      if (type == "gigya") {
        document.cookie = `HORTA_TOKEN=${this.token}; domain=${domain}; path=/`;
      } else {
        document.cookie = `GIAS_USERID=${this.username}; domain=${domain}; path=/`;
        document.cookie = `GIAS_PASSWORD=${pwd}; domain=${domain}; path=/`;
        document.cookie = `SERV_ID=${store.state.servIdIn}; domain=${domain}; path=/api`;
      }
      document.cookie = `GIAS_LANG=${this.globalLocale}; domain=${domain}; path=/`;
    },
    changeStatus() {
      console.log("changestatus");
    },
    showPassword() {
      if (this.eyeIcon == "eye") {
        this.eyeIcon = "eye-slash";
        this.setInputType("text");
      } else {
        this.eyeIcon = "eye";
        this.setInputType("password");
      }
    }
  },
  mounted() {
    this.locale = this.globalLocale.toLowerCase();
    this.urlFlag =
      "https://cloudtest.horta-srl.it/horta_menu/resources/images/flags/" +
      this.locale.toLowerCase() +
      "_w.png";
  },
  created() {
    if (document.cookie.indexOf("ACCEPTED_DISCLAIMER_AGEGO=true") > -1) {
      this.setAcceptDisclaimer(true);
    }
    if (this.acceptDisclaimer) {
      this.getToken()
        .then(response => {
          this.username = response?.username.toLowerCase();
          this.password = response?.password;
          this.locale = this.globalLocale;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
@import "../assets/styles/gigya.css";
</style>
