<template>
  <div>
    <div class="shadowBox titleBox">
      {{ isActive ? masterAttive : masterScadute }}
    </div>
    <div class="calcHeight">
      <div class="flex">
        <div class="paginatorOuter">
          <div :disabled="isDisabledPrima" @click="prima" class="pullLeft">
            <i class="navPrimaAgego iconAgego"></i>
          </div>
          <div
            :disabled="isDisabledIndietro"
            @click="indietro"
            style="margin-left: 2px;"
            class="pullLeft"
          >
            <i class="navIndietroAgego iconAgego"></i>
          </div>
        </div>

        <div class="paginatorInner iconAgego">
          {{ currentPage }} / {{ maxPage }}
        </div>

        <div class="paginatorOuter">
          <div :disabled="isDisabledUltima" @click="ultima" class="pullRight">
            <i class="navUltimaAgego iconAgego"></i>
          </div>
          <div
            :disabled="isDisabledAvanti"
            @click="avanti"
            class="pullRight"
            style="margin-right: 2px;"
          >
            <i class="navAvantiAgego iconAgego"></i>
          </div>
        </div>
      </div>

      <div v-if="this.showMsg" class="alert alert-success marginMsg">
        <span>{{ this.insertMsg }}</span>
      </div>

      <table>
        <tr>
          <th class="up_item_header">
            <span class="vanish">{{ labelGestione }}</span>
          </th>
          <th class="up_item_header" v-if="role === 'ADMIN'">ID</th>
          <th class="up_item_header">
            {{ labelDescrizione }}
          </th>
          <th class="up_item_header">
            {{ labelStazione }}
          </th>
          <th class="up_item_header">
            {{ labelComune }}
          </th>
          <th class="up_item_header">
            <span class="vanish">{{ labelFunz }}</span>
          </th>
        </tr>
        <tr
          class="up_item_row"
          :class="index % 2 == 0 ? 'evenRow' : 'oddRow'"
          v-for="(up, index) in itemList"
          :key="index"
        >
          <td class="center">
            <div @click="openNav(up.UPID)">
              <i
                :class="
                  up.freezed ? 'editAgego iconAgego' : 'editAgegoNC iconAgego'
                "
              ></i>
            </div>
            <div
              :id="`mySidebar_${up.UPID}`"
              class="sidebar sidebarLeft shadow"
            >
              <div class="sideBarTitle">
                {{ up.UPDes }}
                <div @click="closeNav(up.UPID)" class="closeSidebarIcon">
                  &#0215;
                </div>
              </div>
              <div @click="onClickAction(up, 'deleteUp')">
                <i class="cancellaAgego sideBarIcon"></i>
                <a>{{ labelCancella }}</a>
              </div>
              <div @click="onClickAction(up, 'viewUp')">
                <i class="rocAgego sideBarIcon"></i>
                <a>{{ labelScheda }}</a>
              </div>
              <div @click="onClickAction(up, 'editUp')">
                <i class="editAgego sideBarIcon"></i>
                <a>{{ labelMod }}</a>
              </div>
              <div v-if="!up.freezed" @click="onClickAction(up, 'confirmUp')">
                <i class="confermaAgego sideBarIcon"></i>
                <a>{{ labelConfirm }}</a>
              </div>
              <div
                :class="!up.latitudine ? 'disabled' : ''"
                @click="onClickAction(up, 'duplicaUp')"
              >
                <i class="duplicaAgego sideBarIcon"></i>
                <a :disabled="!up.latitudine">{{ labelDuplica }}</a>
              </div>
            </div>
          </td>
          <td v-if="role === 'ADMIN'">{{ up.UPID }}</td>
          <td
            :class="[
              !up.freezed ? 'italic' : '',
              role === 'ADMIN' ? 'wordWrap' : ''
            ]"
          >
            {{ up.UPDes }}
          </td>
          <td :class="!up.freezed ? 'italic' : ''">{{ up.quadranteNome }}</td>
          <td v-if="up.nazISO == 'IT'" :class="!up.freezed ? 'italic' : ''">
            {{ up.comDes }}
          </td>
          <td v-else :class="!up.freezed ? 'italic' : ''">{{ up.nuts3Des }}</td>
          <td class="center">
            <div @click="openFunz(up.UPID)">
              <font-awesome-icon :icon="['fas', 'bars']" class="barsAgego" />
            </div>
            <div
              :id="`funzSidebar_${up.UPID}`"
              class="sidebar sidebarRight shadow"
            >
              <div class="sideBarTitle">
                {{ up.UPDes }}
                <div @click="closeFunz(up.UPID)" class="closeSidebarIcon">
                  &#0215;
                </div>
              </div>
              <div @click="onChange(up, 'compMeteoUp')">
                <i class="meteoAgego sideBarIcon"></i>
                <a>{{ labelMeteo }}</a>
              </div>
              <div
                @click="onChange(up, 'compFenoUp')"
                :class="!up.freezed ? 'disabled' : ''"
              >
                <i class="fenologiaAgego sideBarIcon"></i>
                <a :disabled="!up.freezed">{{ labelFenologia }}</a>
              </div>
              <div
                @click="onChange(up, 'compMalattieUp')"
                :class="!up.freezed ? 'disabled' : ''"
              >
                <i class="malattieAgego sideBarIcon"></i>
                <a :disabled="!up.freezed">{{ labelMalattie }}</a>
              </div>
              <div
                @click="onChange(up, 'compInsettiUp')"
                :class="!up.freezed ? 'disabled' : ''"
              >
                <i class="insettiAgego sideBarIcon"></i>
                <a :disabled="!up.freezed">{{ labelInsetti }}</a>
              </div>
              <div @click="onChange(up, 'compFitoUp')">
                <i class="fitoAgego2 sideBarIcon"></i>
                <a>{{ labelFito }}</a>
              </div>
              <div
                @click="onChange(up, 'compProtUp')"
                :class="!up.freezed ? 'disabled' : ''"
              >
                <i class="protezioneAgego sideBarIcon"></i>
                <a :disabled="!up.freezed">{{ labelProtezione }}</a>
              </div>
              <div
                @click="onChange(up, 'compRocUp')"
                :class="!up.freezed ? 'disabled' : ''"
              >
                <i class="rocAgego sideBarIcon"></i>
                <a :disabled="!up.freezed">{{ labelRoc }}</a>
              </div>
            </div>
          </td>
        </tr>
      </table>

      <div v-if="itemList.length == 0" class="noUpFound">
        {{ noItems }}
      </div>

      <app-dialog />
      <!-- <div id="waitingPanel" class="waiting">
        <div class="headerWaiting">
          {{ labeWaiting }}
        </div>
        <div class="bodyWaiting">
          {{ labelElab }}
        </div>
        <div class="loaderIcon">
          <img src="/../images/agrishare/horta/ajax-loader.gif" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import queries from "../../mixins/queries.js";
import locales from "../../mixins/locales.js";

export default {
  data() {
    return {
      upSection: "",
      upAction: "",
      dialog: false,
      xDown: null,
      yDown: null
    };
  },
  mixins: [queries, locales],
  computed: {
    ...mapGetters({
      compToRender: "compToRender",
      upId: "upId",
      itemInfo: "itemInfo",
      example: "example",
      itemList: "itemList",
      upList: "upList",
      maxPage: "maxPage",
      currentPage: "currentPage",
      totalItem: "totalItem",
      currentSection: "currentSection",
      limit: "limit",
      maxUp: "maxUp",
      token: "token",
      username: "username",
      password: "password",
      insertMsg: "insertMsg",
      showMsg: "showMsg",
      totalList: "totalList",
      isActive: "isActive",
      isDisabledAvanti: "isDisabledAvanti",
      isDisabledIndietro: "isDisabledIndietro",
      isDisabledPrima: "isDisabledPrima",
      isDisabledUltima: "isDisabledUltima",
      globalLocale: "globalLocale",
      role: "role"
    })
  },
  methods: {
    ...mapMutations([
      "setCompToRender",
      "setToken",
      "setItemList",
      "setMaxPage",
      "setCurrentPage",
      "setTotalItem",
      "setCurrentSection",
      "setUpId",
      "setItemInfo",
      "setTotalList",
      "setShowMsg",
      "setInsertMsg",
      "setIsDisabledAvanti",
      "setIsDisabledIndietro",
      "setIsDisabledPrima",
      "setIsDisabledUltima"
    ]),
    onClickAction(up, action) {
      const isDelete = action === "deleteUp";
      const isView = action === "viewUp";
      const isEdit = action === "editUp";
      const isConfirm = action === "confirmUp";
      const isDuplica = action === "duplicaUp";

      this.setItemInfo(up);
      if (isDelete) {
        this.openDeleteDialog(up);
      } else if (isView) {
        this.viewUp();
      } else if (isEdit) {
        this.updateUp();
      } else if (isConfirm && !up.freezed) {
        this.confirmUp();
      } else if (isDuplica) {
        if (this.maxUp != null && this.totalList?.length >= this.maxUp) {
          let message = {
            title: this.labelWarn,
            body: this.maxUpMsg
          };
          let options = {
            okText: this.labelCloseBtn
          };
          this.$dialog.alert(message, options);
        } else {
          this.duplicaUp();
        }
      }
      this.closeall();
    },
    onChange(up, section) {
      if (section) {
        const checkFreeze =
          section === "compFenoUp" ||
          section === "compMalattieUp" ||
          section === "compInsettiUp" ||
          section === "compProtUp" ||
          section === "compRocUp";
        if (checkFreeze && !up.freezed) {
          return;
        }

        this.setItemInfo(up);
        this.setCurrentSection(section);
        this.setCompToRender(section);
        this.closeall();
      }
    },
    fetchData() {
      this.setTotalList([]);
      this.setItemList([]);

      if (!this.isExampleEnabled())
        return this.retrieveMasterUp(
          this.token,
          this.globalLocale,
          this.isActive
        )
          .then(result => {
            this.retrieveUps(result);
          })
          .catch(error => {
            console.log(error);
            if (
              error.toString().indexOf("Request failed with status code 401") >
              -1
            ) {
              this.setCompToRender("compErrorLogin");
            }
          });
      else
        return this.retrieveMasterUpFiltred(
          this.token,
          this.example,
          this.isActive,
          this.globalLocale
        )
          .then(result => {
            this.retrieveUps(result);
          })
          .catch(error => {
            console.log(error);
            if (
              error.toString().indexOf("Request failed with status code 401") >
              -1
            ) {
              this.setCompToRender("compErrorLogin");
            }
          });
    },
    retrieveUps(result) {
      const listUp = result.data.data.masterUP;
      const countUp = listUp.length;
      this.setTotalItem(countUp);
      if (countUp > 0) {
        this.setMaxPage(Math.ceil(this.totalItem / this.limit));
      } else this.setMaxPage(1);

      this.setTotalList(listUp);
      this.slicingList(this.totalList);
      this.checkDisabled();
      this.loaded();
      //
      if (this.currentPage > this.maxPage) {
        this.setCurrentPage(1);
        this.slicingList(this.totalList);
        this.fetchData();
      }
    },
    slicingList(list) {
      const slicedList = list.slice(
        this.limit * (this.currentPage - 1),
        this.limit * this.currentPage
      );
      this.setItemList(slicedList);
    },
    confirmUp() {
      this.mutationConfirmUp(
        this.token,
        this.itemInfo.UPID,
        this.itemInfo.latitudine
      )
        .then(result => {
          if (result.data?.errors.length > 0) {
            this.setInsertMsg(result.data.errors[0].message);
            this.setShowMsg(true);
            this.fetchData();
            setTimeout(() => {
              this.setShowMsg(false);
            }, 2000);
          } else {
            //refresh tabella
            this.setInsertMsg(this.okConfirmMsg);
            this.setShowMsg(true);
            this.fetchData();
          }
        })
        .catch(error => {
          console.log(error);
          this.setInsertMsg(this.errorConfirmMsg);
          this.setShowMsg(true);
        });
    },
    duplicaUp() {
      this.mutationDuplicaUp(
        this.token,
        this.itemInfo.UPID,
        this.itemInfo.latitudine
      )
        .then(() => {
          //refresh tabella
          this.setInsertMsg(this.okDuplicaMsg);
          this.setShowMsg(true);
          this.fetchData();
        })
        .catch(error => {
          console.log(error);
          this.setInsertMsg(this.errorDuplicaMsg);
          this.setShowMsg(true);
        });
    },
    viewUp() {
      this.setCompToRender("compViewUp");
    },
    updateUp() {
      this.setCompToRender("compAddUp");
    },
    openDeleteDialog(up) {
      let message = {
        title: this.labelWarn,
        body: `${this.labelDialogUPDelete}: ${up.UPDes}?`
      };
      let options = {
        okText: this.labelOkBtn,
        cancelText: this.labelCloseBtn
      };
      this.$dialog
        .confirm(message, options)
        .then(() => {
          this.deleteUp(up);
        })
        .catch(error => {
          console.log(error);
        });
    },
    deleteUp(up) {
      this.mutationDeleteUP(this.token, up.UPID)
        .then(result => {
          this.setShowMsg(true);
          if (result.data?.errors?.length > 0) {
            const error = result.data?.errors?.[0];
            console.log(error);
            this.setInsertMsg(this.labelErrorDeleteMsg);
          } else {
            this.setInsertMsg(this.labelOkDeleteMsg);
            this.fetchData();
            setTimeout(() => {
              this.setShowMsg(false);
            }, 2000);
          }
        })
        .catch(error => {
          console.log(error);
          this.setShowMsg(true);
          this.setInsertMsg(this.labelErrorDeleteMsg);
        });
    },
    avanti() {
      if (!this.isDisabledAvanti) {
        this.setCurrentPage(this.currentPage + 1);
        this.slicingList(this.totalList);
        this.checkDisabled();
      }
    },
    indietro() {
      if (!this.isDisabledIndietro) {
        this.setCurrentPage(this.currentPage - 1);
        this.slicingList(this.totalList);
        this.checkDisabled();
      }
    },
    prima() {
      this.setCurrentPage(1);
      this.slicingList(this.totalList);
      this.checkDisabled();
    },
    ultima() {
      this.setCurrentPage(this.maxPage);
      this.slicingList(this.totalList);
      this.checkDisabled();
    },
    checkDisabled() {
      this.setIsDisabledAvanti(this.currentPage >= this.maxPage);
      this.setIsDisabledIndietro(this.currentPage <= 1);
      this.setIsDisabledPrima(this.currentPage <= 1);
      this.setIsDisabledUltima(this.currentPage >= this.maxPage);
    },
    isExampleEnabled() {
      return (
        this.example.UPDes ||
        this.example.IDCli ||
        this.example.pIVAAzi ||
        this.example.nazISO ||
        this.example.regione ||
        this.example.provincia ||
        this.example.quadranteID ||
        this.example.sistemaColturaleID ||
        this.example.varCod ||
        this.example.doseInoculoID ||
        this.example.presenzaMarciumeNero
      );
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    loading() {
      document.getElementById("waitingPanel").style.display = "block";
    },
    loaded() {
      document.getElementById("waitingPanel").style.display = "none";
    },
    openFunz(item) {
      this.closeall();
      document.getElementById("funzSidebar_" + item).style.width = "250px";
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width >= 768)
        document.getElementById("main").style.marginRight = "250px";
    },
    closeFunz(item) {
      document.getElementById("funzSidebar_" + item).style.width = "0";
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width >= 768) document.getElementById("main").style.margin = "0";
    },
    openNav(item) {
      this.closeall();
      document.getElementById("mySidebar_" + item).style.width = "250px";
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width >= 768)
        document.getElementById("main").style.marginLeft = "250px";
    },
    closeNav(item) {
      document.getElementById("mySidebar_" + item).style.width = "0";
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width >= 768) document.getElementById("main").style.margin = "0";
    },
    closeall() {
      this.closeAllSide();
      this.closeAllFunz();
      this.closeMainSidebar();
    },
    closeAllSide() {
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width >= 768) document.getElementById("main").style.margin = "0";
      const x = document.querySelectorAll('[id^="mySidebar_"]');
      for (const value of x) {
        value.style.width = "0";
      }
    },
    closeAllFunz() {
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width >= 768) document.getElementById("main").style.margin = "0";
      const y = document.querySelectorAll('[id^="funzSidebar"]');
      for (const value of y) {
        value.style.width = "0";
      }
    },
    closeMainSidebar() {
      // document.getElementById("mainSideBar").style.width = "0";
      // var width =
      //   window.innerWidth ||
      //   document.documentElement.clientWidth ||
      //   document.body.clientWidth;
      // if (width >= 768) document.getElementById("main").style.marginLeft = "0";
    },
    handleTouchStart(evt) {
      this.xDown = evt.touches[0].clientX;
      this.yDown = evt.touches[0].clientY;
    },
    handleTouchMove(evt) {
      const widthWindow = window.width;
      if (!this.xDown || !this.yDown || widthWindow > 1024) {
        return;
      }

      const xUp = evt.touches[0].clientX;
      const yUp = evt.touches[0].clientY;

      const xDiff = this.xDown - xUp;
      const yDiff = this.yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          /* left swipe */
          this.closeAllSide();
        } else {
          /* right swipe */
          this.closeAllFunz();
        }
      } else {
        if (yDiff > 0) {
          /* up swipe */
          this.closeall();
        } else {
          /* down swipe */
        }
      }
      /* reset values */
      this.xDown = null;
      this.yDown = null;
    }
  },
  created() {
    this.fetchData();
    this.scrollToTop();
    setTimeout(() => {
      this.setShowMsg(false);
    }, 2000);
    document.addEventListener("touchstart", this.handleTouchStart, false);
    document.addEventListener("touchmove", this.handleTouchMove, false);
  }
};
</script>

<style scoped>
.noUpFound {
  padding: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
}

.italic {
  font-style: italic;
}

.wordWrap {
  word-break: break-all;
}
</style>
